@import "../../../../styles/_variables";

.order-item {
  padding: $page-padding;

  h1 {
    margin: 0 0 20px 0;
    padding: 0;
    font-size: 16px;
    text-transform: uppercase;
  }

  .heading {
    color: $app-primary !important;
  }

  .content {
    padding-left: 5px;
  }

  .contents {
    display: inline-block;
  }

  .liststyle {
    display: flex;
    flex-direction: column;
    height: 90%;
  }
}

.field-row {
  margin-bottom: 20px;
}
