@import "../../styles/variables";

.h-app-module-page-content {
  position: absolute;
  top: 0;
  left: $component-padding;
  bottom: $component-padding;
  right: $component-padding;
  padding: $component-padding;
}
