@import "../../../../styles/_variables";

.filterContainer {
  display: inline-block;
  width: 48%;
  height: 30vh;
  background-color: #f5f5f5;
  overflow: hidden;
}
.order-item {
  padding: $page-padding;

  h1 {
    margin: 0 0 20px 0;
    padding: 0;
    font-size: 16px;
    text-transform: uppercase;
  }
  .orderinner {
    display: flex;
    justify-content: space-between;
    padding-right: 2%;
  }
  .heading {
    color: $app-primary !important;
  }

  .content {
    padding-left: 5px;
  }

  .contents {
    display: inline-block;
  }

  .liststyle {
    display: flex;
    flex-direction: column;
  }
}

.advertisements-field {
  margin: 10px 0 !important;
}

#fileSelect {
  box-shadow: $layer-shadow;
  height: 40px;
  border: none;
  background-color: $app-primary;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.h-app-search-box-members {
  box-shadow: $layer-shadow;
  border-radius: $box-border-radius;
  background: $search-box-Background;
  color: $search-box-Foreground;
  width: 96% !important;
  box-sizing: border-box;
  display: flex;
  transition: background 0.2s, border 0.2s;
  border: solid 2px transparent;
  opacity: 0;
  animation: fade-in 0.4s 0.2s forwards;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 4px;

  $input-padding: $component-padding - 2;

  input {
    padding: $input-padding $input-padding $input-padding 0;
  }

  svg {
    margin: 0 $component-padding;
    opacity: 0.3;
  }

  &:hover {
    background: $search-box-hover-Background;
    color: $search-box-hover-Foreground;
    border-color: $search-box-hover-Border;
  }

  &.Mui-focused {
    background: $search-box-focus-Background;
    color: $search-box-focus-Foreground;
    border-color: $search-box-focus-Border;
  }

  .close-search {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    font-size: 20px;
    flex: none;
    display: flex;
    box-sizing: border-box;
  }
}

.h-app-input-field-members {
  margin-top: -6px !important;
  margin-bottom: -6px !important;
}

.pager {
  border-top: solid 1px $horizontalRule-Background;
}

.propertyButton {
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  width: 90% !important;
}

.type-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.category-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add-field {
  min-width: 120px !important;
  min-height: 60px;
  max-height: 60px;
  margin-left: 10px !important;
}

.edit-field {
  min-width: 120px !important;
  min-height: 60px;
  max-height: 60px;
  margin-left: 10px !important;
}

.edit-modal {
  .item-form-field {
    margin: 10px 0;
  }
}

.h-app-modal {
  width: 450px !important;
  height: 310px !important;
  margin: -200px 0 0 -200px !important;

  .modal-body {
    padding: 20px;

    .slug-hint {
      color: gray;
      font-size: 12px;
      margin-left: 5px;
    }
  }
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .option {
    flex: 95;
  }

  .edit-icon {
    font-size: 12px;
    flex: 5;
  }
}
