@import "../../styles/variables";

.h-app-scrollable {
  & > div:last-of-type {
    right: 6px !important;
    width: 8px !important;
    bottom: 6px !important;
    top: 6px !important;

    & > div {
      background-color: $scrollbar-Background !important;
      transition: background-color 0.2s;

      &:hover,
      &:active {
        background-color: $scrollbar-hover-Background !important;
      }
    }
  }
}

@keyframes scrollable-scrollbar-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
