@import "../../styles/variables";

.h-app-library {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: $library-width;
  display: flex;
  flex-direction: column;
  background: $library-Background;
  box-sizing: border-box;
  border-radius: $box-border-radius;
  box-shadow: $layer-shadow;
  overflow: hidden;
  animation: library-in 0.2s forwards;

  .loading {
    opacity: 0.7;
    padding: $component-padding;
    text-align: center;
    display: block;
  }

  & > h1 {
    font-size: 14px;
    text-transform: uppercase;
    margin: 0;
    padding: $component-padding;
    border-bottom: solid 1px $horizontalRule-Background;
  }

  .pager {
    border-top: solid 1px $horizontalRule-Background;
    padding: $component-padding;
    background: $paging-Background;

    & > ul {
      justify-content: center !important;

      .Mui-selected {
        background: $paging-selected-Background !important;
        color: $paging-selected-Foreground !important;
      }
    }
  }

  .h-app-library-card {
    background: $library-item-Background;
    color: $library-item-Foreground;
    border-bottom: solid 1px $horizontalRule-Background;
    display: block;

    button {
      display: block !important;
      text-align: left;
    }

    &:hover {
      background: $library-item-hover-Background;
      color: $library-item-hover-Foreground;
    }

    &.active {
      background: $library-item-active-Background;
      color: $library-item-active-Foreground;
    }
  }
}

@keyframes library-in {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
