@import "../../styles/variables";

.h-app-search-box {
  box-shadow: $layer-shadow;
  border-radius: $box-border-radius;
  background: $search-box-Background;
  color: $search-box-Foreground;
  width: $library-width;
  box-sizing: border-box;
  display: flex;
  transition: background 0.2s, border 0.2s;
  border: solid 2px transparent;
  opacity: 0;
  animation: fade-in 0.4s 0.2s forwards;

  $input-padding: $component-padding - 2;

  input {
    padding: $input-padding $input-padding $input-padding 0;
  }

  svg {
    margin: 0 $component-padding;
    opacity: 0.3;
  }

  &:hover {
    background: $search-box-hover-Background;
    color: $search-box-hover-Foreground;
    border-color: $search-box-hover-Border;
  }

  &.Mui-focused {
    background: $search-box-focus-Background;
    color: $search-box-focus-Foreground;
    border-color: $search-box-focus-Border;
  }

  .close-search {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    font-size: 20px;
    flex: none;
    display: flex;
    box-sizing: border-box;
  }
}
