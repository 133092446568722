@import "../../../../styles/_variables";

.selected-user {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.pending-cancellation-grid {
  .cancellation-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid #ededed;
    padding: 3px;

    .cancel-button {
      min-height: 35px;
    }

    .revert-button {
      color: gray;
    }
  }
}

.cancellation-modal {
  height: 170px;

  .title {
    font-weight: 700;
    font-size: 18px;
  }

  .id-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-top: 15px;

    .event-id {
      font-weight: 100;
      font-size: 14px;
      letter-spacing: 2px;
      margin-left: 5px;
    }

    .event-id-placeholder {
      font-weight: 500;
    }
  }
}
