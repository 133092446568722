@import "../../styles/variables";

.h-app-landing {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $item-index-Foreground;
  animation: $animation-fade-in;

  svg {
    font-size: 40px;
    margin-bottom: 20px;
  }
}
