@import "../../styles/variables";

.h-app-item-page-content {
  position: absolute;
  left: $library-width + $component-padding;
  top: 0;
  bottom: 0;
  right: 0;
  background: $item-Background;
  color: $item-Foreground;
  animation: item-page-in 0.2s forwards;
  border-radius: $box-border-radius;
  box-shadow: $layer-shadow;

  &.index,
  &.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $item-index-Background;
    color: $item-index-Foreground;
    animation: none;
    box-shadow: none;
    animation: $animation-fade-in;

    svg {
      font-size: 40px;
      margin-bottom: 20px;
    }
  }
}

.fullscreen {
  left: $library-width + $component-padding;
  top: 0;
  bottom: 0;
  right: 0;
  background: $item-Background;
  color: $item-Foreground;
  animation: item-page-in 0.2s forwards;
  border-radius: $box-border-radius;
  box-shadow: $layer-shadow;

  &.index,
  &.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $item-index-Background;
    color: $item-index-Foreground;
    animation: none;
    box-shadow: none;
    animation: $animation-fade-in;

    svg {
      font-size: 40px;
      margin-bottom: 20px;
    }
  }
}

@keyframes item-page-in {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
