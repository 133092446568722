@import "../../styles/variables";

.h-app-toolbar {
  display: flex;
  flex-direction: row;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  padding: 0 $component-padding $app-logo-margin $component-padding;
  box-sizing: border-box;
  margin: 0 0 0 ($sideNav-width);
  background: $toolbar-Background;
  color: $toolbar-Foreground;
  flex: none;

  .splitter {
    flex: 0 0 1px;
    display: block;
    background-color: $horizontalRule-Background;
    margin: 0 $component-padding;
    opacity: 0;
    animation: tool-button-in 0.4s 0.2s forwards;
  }

  &:empty {
    display: none;
  }

  .h-app-search-box + .h-app-tool-button {
    margin-left: $component-padding;
  }

  .h-app-tool-button {
    padding: $component-padding;
    border-radius: $box-border-radius;
    margin: 0;
    transition: all 0.2s;
    background: $button-secondary-Background;
    color: $button-secondary-Foreground;
    opacity: 0;
    animation: tool-button-in 0.4s 0.2s forwards;

    &:hover {
      background: $button-secondary-hover-Background;
      color: $button-secondary-hover-Foreground;
    }

    svg {
      margin-right: $component-gutter;
      font-size: 16px;
      margin-top: -2px;
      color: $toolbar-item-icon-Foreground;
      width: 20px;
    }

    &.primary {
      background: $button-primary-Background;
      color: $button-primary-Foreground;
      animation: fade-in 0.4s 0.2s forwards;

      svg {
        color: $button-primary-Foreground;
      }

      &:hover {
        background: $button-primary-hover-Background;
        color: $button-primary-hover-Foreground;
      }
    }

    &.raised {
      box-shadow: $layer-shadow;

      &:active {
        box-shadow: none;
        transform: translateY(3px);
      }
    }

    &.Mui-disabled {
      background: $button-primary-disabled-Background;
      color: $button-primary-disabled-Foreground;
      cursor: no-drop;
    }
  }
}

@keyframes tool-button-in {
  0% {
    transform: translateX(-10px);
    opacity: 0;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}
