@import "../../styles/variables";

.h-app-form {
  display: flex;
  flex-direction: column;
  padding: $page-padding;

  h1 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 $page-padding 0;
    padding: 0;
  }
}
