.h-app-clickable {
  transition: all 0.2s;
  text-decoration: none;
  cursor: pointer;
  color: inherit;

  button {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    align-items: flex-start !important;
    justify-content: left;
  }

  &.active {
    background-color: transparent;
    opacity: 1;
  }
}
