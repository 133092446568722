@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale-pop-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  12% {
    transform: translateX(-10px);
  }
  26% {
    transform: translateX(0);
  }
  38% {
    transform: translateX(10px);
  }
  51% {
    transform: translateX(0);
  }
  63% {
    transform: translateX(-10px);
  }
  76% {
    transform: translateX(0);
  }
  88% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
