.app-logo {
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-image: url("../../../../public/full_logo_dark.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-clip: padding-box;
  border: 10px;

  svg {
    font-size: 30px;
    margin-right: 10px;
  }
}
