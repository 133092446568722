@import "./variables";

html {
  background-color: $window-Background;
}

html,
body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100%;
}

body {
  background: $page-Background;
  color: $page-Foreground;
}

#root,
.h-app-container {
  height: 100%;
}

.h-app-container {
  display: flex;
  flex-direction: column;
  transition: filter 0.3s, background 0.5s;

  &.has-cover {
    filter: blur(10px);
  }

  &.signed-out {
    flex-direction: row;
    justify-content: center;
    background: $page-signed-out-Background;
  }
}

.hide-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@-webkit-keyframes fa-spinner /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fa-spinner {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.fa-spinner {
  -webkit-animation: fa-spinner 1s linear infinite;
  -moz-animation: fa-spinner 1s linear infinite;
  -ms-animation: fa-spinner 1s linear infinite;
  -o-animation: fa-spinner 1s linear infinite;
  animation: fa-spinner 1s linear infinite;
}
