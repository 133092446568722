@import "../../styles/variables";

.h-app-error {
  color: rgb(250, 40, 40);
  text-align: center;
  background-color: rgba(250, 40, 40, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: $page-padding;
  box-sizing: border-box;

  svg {
    display: block;
    font-size: 30px;
    margin: 20px auto;
  }
}
